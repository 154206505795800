import React from 'react'
import Hero from '../components/Hero'
import PatientBehaviorCard from '../components/PatientBehaviorCard'
import StatCard from '../components/StatCard'
import CallToAction from '../components/CallToAction'
import { getLinkToExternalApp } from '../utils/api'
import iconBottleSmile from '../images/icon-bottle-smile.svg'
import iconText from '../images/icon-text.svg'
import iconAlert from '../images/icon-alert.svg'

export default function pharmaciesAndHealthsystemsPage() {
  return (
    <main className="pharamacies-and-healthsystems-page">
      <Hero
        color="blue"
        image="data-devices"
        heading="Actionable adherence data, delivered right to you"
        text="Revolutionary smart technology for pharmacists and care teams to provide the right care at the right time."
      />
      <div className="page-section">
        <div className="page-section-content partners">
          <p>
            AdhereTech partners with many leading specialty pharmacies, hospitals, and health systems to provide
            connected devices and support to improve adherence in their patient populations. With the Aidia System™,
            pharmacists and care teams gain access to dose-level monitoring and alerts for data-driven patient support.{' '}
          </p>
        </div>
        <div className="page-section-content patient-behavior">
          <h2>Take the guesswork out of patient behavior</h2>
        </div>
        <div className="page-section-content refill">
          <p>
            Nonadherence to medication regimens delays refills and can lead to unexplained patient drop off. Refill
            reminders only partially address the issue. Real-time dose-level reporting enables real-time early
            intervention that results in fewer gap days and greater persistence on therapy.{' '}
          </p>
        </div>
        <div className="page-section-content patient-behavior-cards">
          <PatientBehaviorCard
            image={iconBottleSmile}
            altText="icon bottle smile"
            content="Simple enrollment and setup at no cost to the patient. Tracks doses in an easy-to-use, HIPAA-compliant platform."
          />
          <PatientBehaviorCard
            image={iconText}
            altText="icon text"
            content="2-way text and phone communication sends reminders and captures patient feedback regarding late or missed doses."
          />
          <PatientBehaviorCard
            image={iconAlert}
            altText="icon text"
            content="Real-time data fills in the blanks of previously unknown patient behavior between refills."
          />
        </div>
      </div>
      <div className="page-section--blue">
        <div className="page-section-content stat-cards__container">
          <div className="stat-cards">
            <StatCard
              headerText={
                <header>
                  19 <span>fewer</span>
                </header>
              }
              content={
                <span className="subtext">
                  Gap days annually<sup>1</sup>
                </span>
              }
            />
            <StatCard
              headerText={<header>1-2</header>}
              content={
                <span className="subtext">
                  Additional fills{' '}
                  <span>
                    per patient, per year<sup>1-4</sup>
                  </span>
                </span>
              }
            />
            <StatCard
              headerText={
                <header>
                  4.7<span>out of</span> 5
                </header>
              }
              content={
                <span className="subtext">
                  Patient satisfaction<sup>3</sup>
                </span>
              }
            />
          </div>
          <div className="references">
            <span>
              <span className="bold">References: 1.</span> Diplomat Pharmacy. <span className="bold">2.</span> Avella
              Pharmacy. <span className="bold">3.</span>{' '}
              <span className="italic">Journal of Managed Care and Specialty Pharmacy</span> (JMCP).{' '}
              <span className="bold">4.</span> US Bioservices.
            </span>
          </div>
        </div>
      </div>
      <div className="page-section">
        <div className="page-section-content fills-dispensed">
          <h2>Increase pills and fills dispensed </h2>
        </div>
        <div className="page-section-content studies">
          <p>
            Multiple real-world studies show that patients using the Aidia System with pharmacy interventions are highly
            engaged and find the system easy to use. Join numerous leading specialty and independent pharmacies using
            the Aidia System and experience the benefits of real-time data.
          </p>
        </div>
      </div>
      <div className="page-section">
        <div className="page-section-content call-to-action-grid">
          <CallToAction
            headerText="Partner with us"
            content="Speak with a representative about how we can integrate with your pharmacy or health system.  "
            linkText="Contact Us"
            linkUrl="/contact-us?organizationType=pharmacy_health_system"
            color="blue"
          />
          <CallToAction
            headerText="Already a Pharmacy or Health System Partner?"
            content="Log in to our Partner Portal to access your account."
            linkText="Partner log in"
            linkUrl={getLinkToExternalApp('app')}
            color="blue"
            external
          />
        </div>
      </div>
    </main>
  )
}
